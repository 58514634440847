import React, { useState } from "react";
import Icon1 from "../../images/undraw_dev_focus_re_6iwt.svg";
import Icon2 from "../../images/undraw_savings_re_eq4w.svg";
import Icon3 from "../../images/undraw_investing_re_bov7.svg";
import Icon4 from "../../images/undraw_wallet_re_cx9u.svg";
import Icon5 from "../../images/undraw_people_re_8spw.svg";
import {
    ServicesContainer,
    ServicesWrapper,
    ServicesCard,
    ServicesH1,
    ServicesH2,
    ServicesP,
    ServicesIcon,
} from "./Services.styled";
import ServiceModal from "../ServiceDetail/index";

const Services = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedService, setSelectedService] = useState({});

    const servicesData = [
        {
            icon: Icon1,
            title: "Accounting and Bookkeeping",
            description: "Streamline operations with our comprehensive Accounting and Bookkeeping services.",
			detail:"You need to have a greater focus on your business as an entrepreneur and it is time consuming. At MCP Accounting, we assist small to medium businesses with Bookkeeping services so that you have more time to concentrate on your main business activities and strategy development. Also, you can save the cost of hiring an inhouse bookkeeper with our outsourced bookkeeping options. (We are Xero and Quickbooks Certified Advisors). We do Monthly, Quarterly, and Yearly Bookkeeping, performing all aspects of Bookkeeping: Accounts Receivables, Accounts Payables, General Ledger, General Journal, Credit Card and Bank Account Reconciliation, Balance Sheet and Profit and Loss Statement Preparation."
        },
        {
            icon: Icon2,
            title: "Personal Taxes",
            description: "Maximize returns with our expert Personal Tax services.",
			detail:"MCP Accounting offers professional Personal Tax Preparation services. We review your tax status and assess the various tax credits and benefits available to that individual. We help you to reduce your tax liability or to maximize your tax return in accordance with Canadian Tax Regulations. "
        },
        {
            icon: Icon3,
            title: "Corporate Taxes",
            description: "Reduce liability with our Corporate Tax Preparation and filing services.",
			detail:"Canadian Resident Corporations (including non-profit organization, Tax Exempt Corporations and Inactive Corporations) are required to file a T2 return at the end of their financial year, even if there are no tax payable. MCP Accounting offer professional Corporate Tax Preparation and filing services. We assess and review your corporate structure and help you to reduce your tax liability in accordance with Canadian Tax Regulations. "
        },
        {
            icon: Icon4,
            title: "HST Reporting",
            description: "Ensure compliance with our HST Reporting services, timely filed with CRA.",
			detail:"Businesses that have registered for HST are entitled to claim sales taxes paid on purchases and services against sales taxes collected on their sales. We file your HST returns as per required timeline with CRA."
        },
        {
            icon: Icon5,
            title: "Payroll",
            description: "Simplify payroll with our comprehensive Processing and CRA filing services.",
			detail:"At MCP Accounting we do Payroll Processing for your employees, CRA Remittance Payments, T4 Generation for employees, CRA T4 Fiing, Preparation of Record of Employment (ROE), WSIB & Employer Health Tax filing."
        },
    ];

    const openModal = (service) => {
        setSelectedService(service);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <>
            <ServicesContainer id="services">
                <ServicesH1>Our Services</ServicesH1>
                <ServicesWrapper>
                    {servicesData.map((service, index) => (
                        <ServicesCard key={index} onClick={() => openModal(service)}>
                            <ServicesIcon src={service.icon} />
                            <ServicesH2>{service.title}</ServicesH2>
                            <ServicesP>{service.description}</ServicesP>
                        </ServicesCard>
                    ))}
                </ServicesWrapper>
            </ServicesContainer>
            <ServiceModal
                isOpen={modalOpen}
                closeModal={closeModal}
                serviceName={selectedService.title}
                serviceDescription={selectedService.detail}
            />
        </>
    );
};

export default Services;
