import React from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/index";
import ContactUsPage from "./pages/contact";
import { GlobalStyles } from "./components/styles/Global";
import NotFound from "./pages/notfound";

const theme = {
	colors: {
		header: "#7e9175",
		body: "#fff",
		primary: "#7e9175",
		footer: "#003333",
		dark: "#010606",
	},
	mobile: "768px",
};

function App() {

	return (
		<ThemeProvider theme={theme}>
			<>
				<GlobalStyles />
				<Router>
					<Routes>
						<Route path="/contact-us" element={<ContactUsPage />} />
						<Route path="/" element={<Home />} />
						<Route path="*" element={<NotFound/>}/>
					</Routes>
				</Router>
			</>
		</ThemeProvider>
	);
}

export default App;
