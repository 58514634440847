import styled from "styled-components";

export const AboutContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding: 50px 20px;

  @media screen and (max-width: 768px) {
    padding: 30px 10px;
  }

  @media screen and (max-width: 480px) {
    padding: 20px 5px;
  }
`;

export const AboutH1 = styled.h1`
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 30px;
  margin-top: 50px;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

export const ImageContainer = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 50%;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) {
    width: 200px;
    height: 200px;
  }

  @media screen and (max-width: 480px) {
    width: 150px;
    height: 150px;
  }
`;

export const AboutText = styled.p`
  font-size: 1.2rem;
  color: black;
  text-align: center;
  max-width: 800px;
  margin: 30px;

  strong {
    font-weight: bold;
  }

  @media screen and (max-width: 768px) {
    font-size: 1rem;
    margin: 20px;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.9rem;
    margin: 10px;
  }
`;

export const ImageRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 50px;
  height: 100px;
`;


export const ImageWrapper = styled.img`
  width: 80px;
  height: auto;
  margin: 10px;

  @media screen and (max-width: 768px) {
    width: 70px;
  }

  @media screen and (max-width: 480px) {
    width: 65px;
  }
`;

export const ArrowImage = styled.img`
  width: 0.5cm; 
  height: 0.5cm;
  cursor: pointer;
  margin: 45px 0.25cm 0; 
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8; 
  }
`;


