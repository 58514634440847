export const discoverSection = {
	id: "discover",
	lightBg: true,
	lightText: false,
	lightTextDesc: true,
	topLine: "Financial Expertise",
	headline: "Elevate Your Financial Management with Expert Bookkeeping",
	description:
		"Empowering your business success by taking the burden of bookkeeping off your shoulders, ensuring accuracy, compliance, and timely insights to fuel your growth.",
	buttoLabel: "Get started",
	imgStart: false,
	img: require("../../images/Image-1.png"),
	alt: "transactions",
	dark: false,
	primary: false,
	darkText: false,
};