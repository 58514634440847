import React, { useState } from "react";
import {
    HeroContainer,
    HeroBg,
    VideoBg,
    HeroContent,
    HeroH1,
    HeroP,
    HeroBtnWrapper,
    ArrowForward,
    ArrowRight,
    ImageContainer,
    Image,
} from "./Hero.styled";
import { Button } from "../Button.styled";
import Video from "../../videos/video.mp4";
import Image1 from "../../images/CFlag.png";
import Image2 from "../../images/UFlag.png";

const HeroSection = () => {
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    };

    return (
        <HeroContainer>
            <HeroBg>
                <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
            </HeroBg>
            <HeroContent>
                <HeroH1>Welcome to MCP Accounting</HeroH1>
                <HeroP>
                    Empowering your business growth with precision bookkeeping and personalized financial solutions
                </HeroP>
                <ImageContainer>
                    <Image src={Image1} alt="Image 1" />
                    <Image src={Image2} alt="Image 2" />
                </ImageContainer>
                <HeroBtnWrapper>
                    <Button
                        to="services"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-80}
                        onMouseEnter={onHover}
                        onMouseLeave={onHover}
                        primary={true}
                        dark={false}
                    >
                        Learn More {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    );
};

export default HeroSection;
