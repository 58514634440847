import React from 'react'
import Footer from "../components/Footer";
import NotFoundPage from '../components/NotFound';

const NotFound = () => {
  return (
    <>
    <NotFoundPage/>
        </>
  )
}

export default NotFound
