import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';

export const Nav = styled.nav`
	background: ${({ scrollNav }) => (scrollNav ? "#fff" : "rgba(140, 165, 130, 0.5)")};
	height: 80px;
	margin-top: -80px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	position: sticky;
	top: 0;
	z-index: 10;
	box-shadow: ${({ scrollNav }) =>
		scrollNav ? "rgba(31, 30, 30, 0.08) 0px 2px 8px" : "0"};
	transition: 0.5s all ease;

	@media screen and (max-width: 960px) {
		transition: 0.8s all ease;
	}
`;

export const NavbarContainer = styled.div`
	display: flex;
	justify-content: space-between;
	height: 80px;
	z-index: 1;
	width: 100%;
	padding: 0 24px;
	max-width: 1100px;
`;

export const NavLogo = styled(LinkR)`
	color: #fff;
	justify-self: flex-start;
	cursor: pointer;
	font-size: 1.5rem;
	display: flex;
	align-items: center;
	margin-left: 24px;
	font-weight: bold;
	text-decoration: none;
`;

export const NavLogoIcon = styled.img`
	height: 5rem;
	width: 5rem;
	margin-bottom: 5rem;
	margin-top: 5rem;

	@media screen and (max-width: 768px) {
		height: 3rem;
		width: 3rem;
		margin-bottom: 2rem;
		margin-top: 2rem;
	}
`;

export const Container = styled.div`
  min-height: 100vh; 
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff};
  padding: 30px 
`;

export const FormWarp = styled.div`
  width: 100%;
  max-width: 600px; 
`;

export const Icon = styled(LinkR)`
  margin-bottom: 32px;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  font-size: 32px;

  @media screen and (max-width: 480px) {
    margin-bottom: 16px;
    font-size: 24px;
  }
`;

export const FormContent = styled.div`
  padding: 50px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 480px) {
    padding: 16px;
  }
`;

export const Form = styled.form`
  display: grid;
  gap: 16px;
  padding:25px
`;

export const FormH1 = styled.h1`
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.dark};
  font-size: 24px;

  @media screen and (max-width: 480px) {
    font-size: 20px;
  }
`;

export const FormLabel = styled.label`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.dark};
`;

export const FormInput = styled.input`
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  font-size: 16px;

  @media screen and (max-width: 480px) {
    padding: 10px;
    font-size: 14px;
  }
`;

export const FormButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  color: #fff;
  padding: 14px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    padding: 12px;
    font-size: 14px;
  }
`;
