import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

export const Nav = styled.nav`
	background: ${({ scrollNav }) => (scrollNav ? "#fff" : "transparent")};
	height: 80px;
	margin-top: -80px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	position: sticky;
	top: 0;
	z-index: 10;
	box-shadow: ${({ scrollNav }) =>
		scrollNav ? "rgba(31, 30, 30, 0.08) 0px 2px 8px" : "0"};
	transition: 0.5s all ease;

	@media screen and (max-width: 960px) {
		transition: 0.8s all ease;
	}
`;

export const NavbarContainer = styled.div`
	display: flex;
	justify-content: space-between;
	height: 80px;
	z-index: 1;
	width: 100%;
	padding: 0 24px;
	max-width: 1100px;
`;

export const NavLogo = styled(LinkR)`
	color: #fff;
	justify-self: flex-start;
	cursor: pointer;
	font-size: 1.5rem;
	display: flex;
	align-items: center;
	margin-left: 24px;
	font-weight: bold;
	text-decoration: none;
`;

export const NavLogoIcon = styled.img`
	height: 5rem;
	width: 5rem;
	margin-bottom: 5rem;
	margin-top: 5rem;

	@media screen and (max-width: 768px) {
		height: 3rem;
		width: 3rem;
		margin-bottom: 2rem;
		margin-top: 2rem;
	}
`;

export const MobileIcon = styled.div`
	display: none;

	@media screen and (max-width: 768px) {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 60%);
		font-size: 1.8rem;
		cursor: pointer;
		color: ${({ scrollNav }) =>
			scrollNav ? ({ theme }) => theme.colors.primary : "#fff"};
	}
`;

export const NavMenu = styled.ul`
	display: flex;
	align-items: center;
	list-style: none;
	text-align: center;
	margin-right: -22px;

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

export const NavItem = styled.li`
	height: 80px;
`;

export const NavLinks = styled(LinkS)`
	color: ${({ scrollNav }) => (scrollNav ? "#000" : "#fff")};
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0 1rem;
	height: 100%;
	cursor: pointer;

	&.active {
		border-bottom: 3px solid ${({ theme }) => theme.colors.primary};
	}
`;

export const NavBtn = styled.nav`
	display: flex;
	align-items: center;

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

export const NavBtnLink = styled(LinkR)`
	border-radius: 50px;
	background: ${({ theme }) => theme.colors.primary};
	white-space: nowrap;
	padding: 10px 22px;
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	outline: none;
	border: 0.8px solid transparent;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: none;

	&:hover {
		transition: all 0.2s ease-in-out;
		background: #fff;
		color: ${({ theme }) => theme.colors.primary};
		border: 0.8px solid ${({ theme }) => theme.colors.primary};
	}
`;
