import React, { useState, useEffect } from "react";
import {
  AboutContainer,
  AboutH1,
  ImageContainer,
  AboutText,
  ImageRow,
  ImageWrapper,
  ArrowImage // Remove the duplicate import
} from "./About.styled"; // Use the styled component from the styled file
import Owner from "../../images/owner.jpeg";
import B1 from "../../images/B1.png";
import B2 from "../../images/B2.png";
import B3 from "../../images/B3.png";
import B4 from "../../images/B4.png";
import B5 from "../../images/B5.png";
import Back from "../../images/Back.png";
import Forward from "../../images/Forward.png";

const images = [B5, B4, B3, B1, B2];

const About = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  const goToPrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <AboutContainer id="about">
      <AboutH1>Meet Our Accountant</AboutH1>
      <ImageContainer src={Owner} alt="Owner" />
      <AboutText>
        <strong> Merita Christine Perera (ACMA, CGMA) </strong>
        <br />
        <br />
        Merita Christine Perera carries the ACMA (Associate Chartered Management
        Accountant) and CGMA (Chartered Global Management Accountant)
        designations, bestowed upon her by the AICPA & CIMA.
      </AboutText>

      <AboutText>
        Our mission is to provide high quality Accounting & Bookkeeping services
        that help small-medium businesses in Canada and the USA to manage their
        finances more effectively.
      </AboutText>

      <AboutText>
        With our expertise and personalized approach, we ensure compliance,
        accuracy, and timely results. Our commitment to client satisfaction and
        tailored solutions sets us apart. Trust us to handle your financial
        needs while you focus on growing your business with confidence.
      </AboutText>

      <AboutText>
        MCP Accounting is a member of the London Chamber of Commerce. We have
        earned Digital Mindset Badge issued by AICPA & CIMA which has developed
        the capabilities and confidence needed to not just survive but thrive in
        the digital future. Also we are Certified Advanced Quickbooks Online
        ProAdvisors. We ensure that we serve our clients better with the best in
        the industry.
      </AboutText>

      <ImageRow>
        <ArrowImage src={Back} alt="Previous" onClick={goToPrevImage} />
        {images.map((image, index) => {
          const isCurrent =
            index === currentImageIndex || index === currentImageIndex + 1;
          return (
            <ImageWrapper
              key={index}
              src={image}
              alt={`Image ${index + 1}`}
              style={{ display: isCurrent ? "block" : "none" }}
            />
          );
        })}
        <ArrowImage src={Forward} alt="Next" onClick={goToNextImage} />
      </ImageRow>
    </AboutContainer>
  );
};

export default About;
