import React from "react";
import {
	FooterContainer,
	FooterWrap,
	FooterLinksContainer,
	FooterLinksWrapper,
	FooterLinkItems,
	FooterLinkTitle,
	FooterLink,
	FooterLinkScroll,
	FooterA,
	SocialMedia,
	SocialMediaWrap,
	SocialLogo,
	WebsiteRights,
	SocialIcons,
	SocialIconLink,
	SocialLogoIcon,
} from "./Footer.styled";
import { animateScroll as scroll } from "react-scroll";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import Nova from "../../images/nova-002.png";

const Footer = () => {
	const toggleHome = () => {
		scroll.scrollToTop();
	};

	return (
		<FooterContainer>
			<FooterWrap>
				<FooterLinksContainer>
					<FooterLinksWrapper>
						<FooterLinkItems>
							<FooterLinkTitle>About Us</FooterLinkTitle>
							<FooterLinkScroll
								to="/"
								smooth={true}
								duration={500}
								spy={true}
								exact="true"
								offset={-80}
								onClick={toggleHome}
							>
								Home
							</FooterLinkScroll>
							<FooterLinkScroll
								to="services"
								smooth={true}
								duration={500}
								spy={true}
								exact="true"
								offset={-80}
							>
								Services
							</FooterLinkScroll>
							<FooterLinkScroll
								to="about"
								smooth={true}
								duration={500}
								spy={true}
								exact="true"
								offset={-80}
							>
								Testimonials
							</FooterLinkScroll>
							<FooterLinkScroll
								to="discover"
								smooth={true}
								duration={500}
								spy={true}
								exact="true"
								offset={-80}
							>
								FAQ
							</FooterLinkScroll>
						</FooterLinkItems>

						<FooterLinkItems>
							<FooterLinkTitle>Find Us</FooterLinkTitle>
							<FooterLinkScroll
								to=""
								smooth={true}
								duration={500}
								spy={true}
								exact="true"
								offset={-80}
								onClick={toggleHome}
							>
								784 Little Grey ST Unit B London ON N5Z 1P1
							</FooterLinkScroll>
							<FooterLinkScroll
								to=""
								smooth={true}
								duration={500}
								spy={true}
								exact="true"
								offset={-80}
								onClick={toggleHome}
							>
								Business Hours: Mon-Fri 9am-5pm
							</FooterLinkScroll>
							<FooterA href="mailto:info@mcpaccounting.com">
								Email Us : info@mcpaccounting.com
							</FooterA>
							<FooterA href="tel:+1 (226) 884-6888">
								Call Us: +1 (226) 884-6888
							</FooterA>
							<FooterA href="tel:+1 (647) 407-8695" style={{ marginLeft: '55px'}}>
							    +1 (647) 407-8695
							</FooterA>
						</FooterLinkItems>
						{/* </FooterLinksWrapper>

					<FooterLinksWrapper> */}
						<FooterLinkItems>
							<FooterLinkTitle>Legal</FooterLinkTitle>
							<FooterLink to="/terms-of-service">Terms of Service</FooterLink>
							<FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
						</FooterLinkItems>

						<FooterLinkItems>
							<FooterLinkTitle>Social Media</FooterLinkTitle>
							<FooterLink to="https://www.instagram.com/mcpaccounting_">
								Instagram
							</FooterLink>
							<FooterLink to="https://www.facebook.com/profile.php?id=100090837730861">
								Facebook
							</FooterLink>
						</FooterLinkItems>
					</FooterLinksWrapper>
				</FooterLinksContainer>
				<SocialMedia>
					<SocialMediaWrap>
						<SocialLogo to="/" onClick={toggleHome}>
							<SocialLogoIcon src={Nova}></SocialLogoIcon>
						</SocialLogo>
						<WebsiteRights>
							{" "}
							&copy; MCP Accounting {new Date().getFullYear()} All rights
							reserved. Created with ❤️
						</WebsiteRights>
						<SocialIcons>
							<SocialIconLink
								href="https://www.facebook.com/profile.php?id=100090837730861"
								target="_blank"
								aria-label="Facebook"
							>
								<FaFacebook />
							</SocialIconLink>
							<SocialIconLink
								href="https://www.instagram.com/mcpaccounting_"
								target="_blank"
								aria-label="Instagram"
							>
								<FaInstagram />
							</SocialIconLink>
						</SocialIcons>
					</SocialMediaWrap>
				</SocialMedia>
			</FooterWrap>
		</FooterContainer>
	);
};

export default Footer;
