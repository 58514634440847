import React, { useState } from "react";
import emailjs from "emailjs-com";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavLogoIcon,
  Container,
  FormContent,
  Form,
  FormH1,
  FormWarp,
  Icon,
  FormLabel,
  FormInput,
  FormButton,
} from "./Contact.styled";
import Logo from "../../images/logo-removebg.png";

const ContactUs = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleHome = () => {
    window.location.href = "/";
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ew2z9zj",
        "template_ojy5qsr",
        e.target,
        "2gusaS8T8iXJEFP99"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Your message has been sent successfully!");
        },
        (error) => {
          console.log(error.text);
          alert(
            "There was an error sending your message. Please try again later."
          );
        }
      );

    e.target.reset();
  };

  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/" onClick={toggleHome}>
            <NavLogoIcon src={Logo} />
          </NavLogo>
        </NavbarContainer>
      </Nav>

      <Container>
        <FormWarp>
          <Icon to="/">MCP</Icon>
          <FormContent>
            <Form onSubmit={sendEmail}>
              <FormH1>Get in Touch</FormH1>
              <FormLabel htmlFor="name">Name</FormLabel>
              <FormInput type="text" name="name" required />
              <FormLabel htmlFor="email">Email</FormLabel>
              <FormInput type="email" name="email" required />
              <FormLabel htmlFor="phone">Telephone Number</FormLabel>
              <FormInput type="tel" name="phone" required />
              <FormLabel htmlFor="message">Message</FormLabel>
              <FormInput as="textarea" name="message" rows="4" required />
              <FormButton type="submit">Continue</FormButton>
            </Form>
          </FormContent>
        </FormWarp>
      </Container>
    </>
  );
};

export default ContactUs;
