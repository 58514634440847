import React from 'react'
import ContactUs from '../components/Contact'
import Footer from "../components/Footer";

const ContactUsPage = () => {
  return (
    <>
    <ContactUs/>
    <Footer/>
    </>
  )
}

export default ContactUsPage
