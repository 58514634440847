import React, { useEffect } from "react";
import { ModalBackground, ModalContent, CloseButton, Topic, Description } from "./ServiceDetail.styled";

const ServiceModal = ({ isOpen, closeModal, serviceName, serviceDescription }) => {
    useEffect(() => {

        if (isOpen) {
            document.body.style.overflow = "hidden"; 
        }

        return () => {
            document.body.style.overflow = "unset"; 
        };
    }, [isOpen]);

    return (
        <>
            {isOpen && (
                <ModalBackground onClick={closeModal}>
                    <ModalContent onClick={(e) => e.stopPropagation()}>
                        <CloseButton onClick={closeModal}>X</CloseButton>
                        <Topic>{serviceName}</Topic>
                        <Description>{serviceDescription}</Description>
                    </ModalContent>
                </ModalBackground>
            )}
        </>
    );
};

export default ServiceModal;



